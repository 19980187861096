import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {Card, Col, Row} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {setDefaultLocale,registerLocale} from "react-datepicker";
import {useEffect, useState} from "react";
import JSONForm from '@rjsf/bootstrap-4';
import validator from '@rjsf/validator-ajv8';
import moment from 'moment'
import ru from 'date-fns/locale/ru';
import "react-datepicker/dist/react-datepicker.css";

const minDate = new Date(1900, 1, 1, 0, 0, 0);


const url = process.env.REACT_APP_URL || ''

function get(id, token) {
    return new Promise(function (resolve, reject) {
        fetch(url + "/violations/" + id
            , {
                headers: {
                    'Authorization': token
                }
            }
        ).then(r => {
            if (r.ok) {
                return r.json().then(data => {
                    resolve(data)
                })
            } else {
                reject()
            }
        }).catch(reject)
    })
}


const cmp = (a, b) => {
    return (a || "").toString().toLowerCase() === (b || "").toString().toLowerCase()
}

const DescriptionFieldTemplate = ({id, description}) => {
    return <p id={id} dangerouslySetInnerHTML={{__html: description}}></p>
}

function create(id, revision_id, token) {
    return new Promise((resolve, reject) => {
        fetch(url + "/violations", {
            method: "POST",
            headers: {
                Authorization: token
            },
            body: JSON.stringify({id, revision_id})
        }).then(r => {
            if (r.ok) {
                console.log(r)
                return r.json().then(resolve)
            } else {
                reject()
            }
        }).catch(reject)
    })
}

function update(id, data, token) {
    return new Promise(function (resolve, reject) {
        fetch(url + "/violations/" + id, {
            method: "PUT",
            headers: {
                Authorization: token
            },
            body: JSON.stringify(data)
        }).then(r => {
            if (r.ok) {
                return r.json().then(data => {
                    resolve(data)
                })
            } else {
                reject()
            }
        }).catch(reject)
    })
}

function approve(id, token) {
    return new Promise(function (resolve, reject) {
        fetch(url + "/violations/" + id + "/approve", {
            method: "POST",
            headers: {
                Authorization: token
            },
            body: "{}"
        }).then(r => {
            if (r.ok) {
                return resolve()
            } else {
                reject()
            }
        }).catch(reject)
    })
}

function Address({value, onChange}) {
    const [aStreet, aHouse, aCampus, aApartment] = (value || "").split(", ")
    const [street, setStreet] = useState(aStreet)
    const [house, setHouse] = useState(aHouse)
    const [campus, setCampus] = useState(aCampus)
    const [apartment, setApartment] = useState(aApartment)

    useEffect(() => {
        onChange({target: {value: [street, house, campus, apartment].join(", ").replace(/(,\s)+,?$/, "")}})
    }, [street, house, campus, apartment])

    return <Row>
        <Col>
            <Form.Group>
                <Form.Label>Улица</Form.Label>
                <Form.Control
                    onChange={e => setStreet(e.target.value)}
                    value={street}
                    type="text"
                    placeholder="Улица"/>
            </Form.Group>
        </Col>
        <Col md={{span: 2}}>
            <Form.Group>
                <Form.Label>Дом</Form.Label>
                <Form.Control
                    onChange={e => setHouse(e.target.value)}
                    value={house}
                    type="text"
                    placeholder="Дом"/>
            </Form.Group>
        </Col>
        <Col md={{span: 2}}>
            <Form.Group>
                <Form.Label>Кор/Стр</Form.Label>
                <Form.Control
                    onChange={e => setCampus(e.target.value)}
                    value={campus}
                    type="text"
                    placeholder="Кор/Стр"/>
            </Form.Group>
        </Col>
        <Col md={{span: 2}}>
            <Form.Group>
                <Form.Label>Кв</Form.Label>
                <Form.Control
                    onChange={e => setApartment(e.target.value)}
                    value={apartment}
                    type="text"
                    placeholder="Квартира"/>
            </Form.Group>
        </Col>
    </Row>
}

function Location({countries, token, value, onChange, loadStates, loadAreas, loadCities}) {
    const [states, setStates] = useState([])
    const [areas, setAreas] = useState([])
    const [cities, setCities] = useState([])
    const [country, setCountry] = useState(value?.country)
    const [state, setState] = useState(value?.state)
    const [area, setArea] = useState(value?.area)
    const [city, setCity] = useState(value?.city)

    useEffect(() => {
        getStates(value?.country).then(data => getAreas(data, value?.state)).then(data => getCities(data, value?.area))
    }, [])

    useEffect(()=> {
        setCountry(countries.find(s=>s.id === value?.country || cmp(s.name, value?.country))?.id)
        setState(states.find(s=>s.id === value?.state || cmp(s.name, value?.state))?.id)
        setArea(areas.find(s=>s.id === value?.area || cmp(s.name, value?.area))?.id)
        setCity(cities.find(s=>s.id === value?.city || cmp(s.name, value?.city))?.id)
    },[value])

    const getStates = (country) => {
        const countryId = countries.find(e => e.id === country || cmp(e.name, country))?.id
        if (!countryId) {
            return new Promise((resolve) => resolve([]))
        }
        return loadStates(countryId).then(data => {
            return new Promise(resolve => {
                if (data) {
                    setStates(data)
                    setCountry(countryId)
                    resolve(data)
                }
            })
        })
    }
    const getAreas = (states, state) => {
        if (!state) {
            return new Promise((resolve) => resolve([]))
        }
        const stateId = states.find(e => e.id === parseInt(state) || cmp(e.name, state))?.id
        if (!stateId) {
            return new Promise((resolve) => resolve([]))
        }

        return loadAreas(stateId).then(data => {
            return new Promise(resolve => {
                if (data) {
                    setAreas(data)
                    setState(stateId)
                    resolve(data)
                }
            })
        })
    }

    const getCities = (areas, area, city) => {
        if (!area) {
            area = city
        }
        if (!area) {
            return new Promise((resolve) => resolve([]))
        }
        const areaId = areas.find(e => e.id === parseInt(area) || cmp(e.name, area))?.id
        if (!areaId) {
            return new Promise((resolve) => resolve([]))
        }
        return loadCities(areaId).then(data => {
            return new Promise(resolve => {
                if (data) {
                    setCities(data);
                    setArea(areaId)
                    if (city) {
                        setCity(data.find(e => e.id === parseInt(city) || cmp(e.name, city))?.id)
                    }
                    resolve(data)
                }
            })
        })
    }

    useEffect(() => {
        getStates(value?.country).then(states => getAreas(states, value?.state)).then(areas => getCities(areas, value?.area, value?.city)).catch(e => {
        })
    }, [])

    useEffect(() => {
        if (country) {
            getStates(country)
            const val = countries.find(c => c.id === country)?.name
            if (!cmp(val, value?.country)) {
                onChange({country: val})
            }
        }
    }, [country])

    useEffect(() => {
        if (state) {
            getAreas(states, state)
            const val = states.find(c => c.id === state || cmp(c.name, state))?.name
            if (!cmp(val, value?.state)) {
                onChange({state: val})
            }
        }

    }, [state])

    useEffect(() => {
        if (area) {
            getCities(areas, area)
            const val = areas.find(c => c.id === area)?.name
            if (!cmp(val, value?.state)) {
                onChange({area: val})
            }
        }
    }, [area])

    useEffect(() => {
        if (city) {
            const val = cities.find(c => c.id === city)?.name
            if (!cmp(val, value?.city)) {
                onChange({city: val})
            }
        }
    }, [city])

    return <Form>
        <Form.Group>
            <Form.Label>Страна</Form.Label>
            <Form.Control as={"select"} onChange={e => {
                setCountry(e.target.value)
                setState(0)
                setArea(0)
                setCity(0)
            }} value={country}>
                <option>-</option>
                {countries.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
            </Form.Control>
        </Form.Group>
        <Form.Group>
            <Form.Label>Субъект РФ</Form.Label>
            <Form.Control as={"select"} onChange={e => {
                setState(parseInt(e.target.value))
                setArea(0)
                setCity(0)
            }} value={state}>
                <option>-</option>
                {states.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
            </Form.Control>
        </Form.Group>
        <Form.Group>
            <Form.Label>Район</Form.Label>
            <Form.Control as={"select"} onChange={e => {
                setArea(parseInt(e.target.value))
                setCity(0)
            }} value={area}>
                <option>-</option>
                {areas.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
            </Form.Control>
        </Form.Group>
        <Form.Group>
            <Form.Label>Город</Form.Label>
            <Form.Control as={"select"} onChange={e => {
                setCity(parseInt(e.target.value))
            }} value={city}>
                <option>-</option>
                {cities.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
            </Form.Control>
        </Form.Group>
    </Form>
}


function App({id, revisionId, refresh, token}) {
    registerLocale('ru', ru)

    const [sameAddress, setSameAddress] = useState(true)
    const [form, setForm] = useState({is_address_same_registration: true})
    const [countries, setCountries] = useState([])
    const [documents, setDocuments] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [busy, setBusy] = useState(true)
    const [timer, setTimer] = useState(null)
    const [additionalInformationScheme, setAdditionalInformationScheme] = useState({})

    const [birthDate, setBirthDate] = useState()

    const debounce = (func, timeout) => {
        clearTimeout(timer)
        setTimer(setTimeout(() => func(), timeout))
    }
    const [mode, setMode] = useState("both")
    const [cache, setCache] = useState({
        states: {},
        areas: {},
        cities: {},
    })
    const loadLocation = (type, id, path) => {
        if (cache[type][id]) {
            return new Promise(resolve => resolve(cache[type][id]))
        }
        return fetch(url + path, {
            headers: {
                Authorization: token
            },
        }).then(r => {
            if (r.ok) {
                return r.json().then(data => {
                    setCache({...cache, [type]: {...cache[type], [id]: data}})
                    return new Promise(resolve => resolve(data))
                })
            }
        })
    }

    const loadStates = (country) => {
        return loadLocation("states", country, "/countries/" + country + "/states")
    }
    const loadAreas = (state) => {
        return loadLocation("areas", state, "/states/" + state + "/areas")
    }
    const loadCities = (area) => {
        return loadLocation("cities", area, "/areas/" + area + "/cities")
    }

    useEffect(() => {
        fetch(url + "/documents", {
            headers: {
                Authorization: token
            },
        }).then(r => {
            if (r.ok) {
                return r.json().then(data => {
                    return setDocuments(data)
                })
            }
        })
        fetch(url + "/countries", {
            headers: {
                Authorization: token
            },
        }).then(r => {
            if (r.ok) {
                return r.json().then(data => {
                    return setCountries(data)
                })
            }
        })
    }, [])

    useEffect(() => {
        if (countries.length > 0 && documents.length > 0) {
            get(id, token).then(data => {
                if (data.approved_at) {
                    refresh()
                    return
                }
                setForm(data)
                if (moment(form.date_of_birth).diff(moment(minDate), "days") < 0 ) {
                    setBirthDate(minDate)
                } else {
                    setBirthDate(moment(form.date_of_birth).toDate())
                }
            }).catch(e => {
                create(id, revisionId, token).then(data => {
                    if (moment(form.date_of_birth).diff(moment(minDate), "days") < 0 ) {
                        setBirthDate(minDate)
                    } else {
                        setBirthDate(moment(form.date_of_birth).toDate())
                    }
                    setForm(data)
                })
            }).then(e => setLoaded(true))
        }

    }, [countries, documents])


    useEffect(() => {
        debounce(() => {
            setBusy(true)
            update(id, form, token).
                then(e=>setBusy(false)).
                catch(e=>setBusy(false))
        }, 400)

        if (form.violation_country && form.violation_state) {
            fetch(url + "/additionalInformationScheme?country=" + (countries.find(e => e.id === form.violation_country || e.name === form.violation_country) || {}).name + "&state=" + form.violation_state, {
                headers: {
                    Authorization: token
                }
            }).then(e => e.json()).then(
                data => setAdditionalInformationScheme(data)
            ).catch(e => {
            })
        }
    }, [form])


    const change = (attribute, prepare) => {
        return (e) => {
            setForm({...(form || {}), [attribute]: prepare ? prepare(e.target.value) : e.target.value})
        }
    }
    const print = (mode) => {
        window.location = url + '/violations/' + id + '/pdf?mode=' + mode + '&authorization_token=' + token;
    }

    const submit = (e) => {
        approve(id, token).then(e => {
            window.localStorage.removeItem("id")
            refresh()
        })
    }

    const templateSchema = (schema) => {
        if (schema.description) {
            schema.description = schema.description.replace(/\{\{id\}\}/g, id)
        }
        return schema
    }
    const templateSchemaUI = (ui) => {
        return ui
    }

    if (!loaded) {
        return <Container>
            <div className={"loader"}></div>
        </Container>
    }

    return (
        <Container>
            <h3>Форма нарушения</h3>
            {form && <Form>
                <Row>
                    <Col lg={{span: 4}} md={{span: 12}}>
                        <Form.Group>
                            <Form.Label>Фамилия</Form.Label>
                            <Form.Control onChange={change("last_name")} value={form.last_name}
                                          type="text" placeholder="Фамилия"/>
                        </Form.Group>
                    </Col>
                    <Col lg={{span: 4}} md={{span: 12}}>
                        <Form.Group>
                            <Form.Label>Имя</Form.Label>
                            <Form.Control onChange={change("first_name")} value={form.first_name}
                                          type="text" placeholder="Имя"/>
                        </Form.Group>
                    </Col>
                    <Col lg={{span: 4}} md={{span: 12}}>
                        <Form.Group>
                            <Form.Label>Отчество</Form.Label>
                            <Form.Control type="text" onChange={change("patronymic")} value={form.patronymic}
                                          placeholder="Отчество"/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Дата рождения</Form.Label><br/>
                            <DatePicker
                                minDate={minDate}
                                className={"form-control"}
                                dateFormat={"dd.MM.yyyy"}
                                selected={birthDate}
                                locale="ru"
                                showYearDropdown
                                onChange={date => {
                                    setBirthDate(date)
                                    if (moment(date).isValid()) {
                                        change("date_of_birth")({target: {value: moment(date).format("YYYY-MM-DD") + "T00:00:00Z"}})
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Место рождения</Form.Label>
                            <Form.Control type="text" onChange={change("place_of_birth")} value={form.place_of_birth}
                                          placeholder="Место рождения"/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {moment().diff(form.date_of_birth, "years") < 18 &&
                            <span className="badge" style={{
                                width: "100%",
                                background: "red",
                                fontSize: 16,
                                fontWeight: 400,
                                color: "white"
                            }}>Не допускается выдача штрафа для пассажиров моложе 18 лет</span>}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Гражданство</Form.Label>
                            <Form.Control
                                as={"select"}
                                onChange={change("citizenship")}
                                value={form.citizenship}>
                                <option>-</option>
                                {countries.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Место работы</Form.Label>
                            <Form.Control onChange={change("job_place")} value={form.job_place} type="text"
                                          placeholder="Место работы"/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Должность</Form.Label>
                            <Form.Control onChange={change("job_position")} value={form.job_position} type="text"
                                          placeholder="Должность"/>
                        </Form.Group>
                    </Col>
                </Row>
                <br/>
                <h4>Документ</h4>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Control as={"select"} onChange={change("document_type")}
                                          value={form.document_type}>
                                <option>-</option>
                                {documents.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <br/>
                <Card>
                    <Card.Body>
                        {documents.map(f => {
                            if (f.id === form.document_type) {
                                return <JSONForm key={f.id}
                                                 templates={{DescriptionFieldTemplate: DescriptionFieldTemplate}}
                                                 onChange={e => change("document")({target: {value: e.formData}})}
                                                 schema={templateSchema(f.schema.schema)}

                                                 uiSchema={templateSchemaUI(f.schema.ui)}
                                                 validator={validator}
                                                 formData={form.document}/>
                            }
                            return null;
                        })}
                    </Card.Body>
                </Card>
                <br/>
                <h4>Адрес регистрации</h4>
                <Card>
                    <Card.Body>
                        <Location
                            countries={countries}
                            token={token}
                            value={{
                                country: form?.country,
                                state: form?.state,
                                area: form?.area,
                                city: form?.city,
                            }}
                            onChange={data => {
                                setForm({...form, ...data})
                            }}
                            loadAreas={loadAreas}
                            loadCities={loadCities}
                            loadStates={loadStates}
                        />
                        <Address onChange={change("address")} value={form.address}/>
                    </Card.Body>
                </Card>
                <br/>
                <h4>Адрес фактического проживания</h4>
                <Form.Check label="Совпадает с адресом регистрации" checked={form.is_address_same_registration}
                            onChange={e => change("is_address_same_registration")({target: {value: e.target.checked}})}/>
                {!form.is_address_same_registration && <Card>
                    <Card.Body>
                        <Location countries={countries} token={token} value={{
                            country: form?.fact_country,
                            state: form?.fact_state,
                            area: form?.fact_area,
                            city: form?.fact_city,
                        }} onChange={data => {
                            const ret = Object.keys(data).reduce((p, n) => ({...p, ["fact_" + n]: data[n]}), {})
                            setForm({...form, ...ret})
                        }} loadAreas={loadAreas} loadCities={loadCities} loadStates={loadStates}/>
                        <Address onChange={change("fact_address")} value={form.fact_address}/>
                    </Card.Body>
                </Card>}

                <Form.Group>
                    <Form.Label>Контактный телефон</Form.Label>
                    <Form.Control onChange={change("phone")} value={form.phone} type="text"
                    />
                </Form.Group>
                <Form.Check label="Согласен на СМС-информирование" checked={form.is_sms_notification_permit}
                            onChange={e => change("is_sms_notification_permit")({target: {value: e.target.checked}})}/>

                <br/>
                <h4>Сведения о нарушении</h4>
                <Card>
                    <Card.Body>
                        <Form.Group>
                            <Form.Label>Автобус</Form.Label>
                            <Form.Control onChange={change("vehicle_model")} value={form.vehicle_model} type="text"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Регистрационный знак</Form.Label>
                            <Form.Control onChange={change("vehicle_number")} value={form.vehicle_number} type="text"
                                          placeholder={"A000AA00"}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Эксплуатант</Form.Label>
                            <Form.Control onChange={change("carrier")} value={form.carrier} type="text"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Маршрут</Form.Label>
                            <Form.Control onChange={change("route")} value={form.route} type="text"
                            />
                        </Form.Group>
                        <br/>
                        <h4>Место нарушения</h4>
                        <Card>
                            <Card.Body>
                                {form?.violation_country}
                                <Location
                                    countries={countries}
                                    token={token}
                                    value={{
                                        country: form?.violation_country,
                                        state: form?.violation_state,
                                        area: form?.violation_area,
                                        city: form?.violation_city,
                                    }} onChange={data => {
                                    const ret = Object.keys(data).reduce((p, n) => ({
                                        ...p,
                                        ["violation_" + n]: data[n]
                                    }), {})
                                    setForm({...form, ...ret})
                                }} loadAreas={loadAreas} loadCities={loadCities} loadStates={loadStates}/>
                                <Form.Group>
                                    <Form.Label>Адрес</Form.Label>
                                    <Form.Control onChange={change("violation_address")} value={form.violation_address}
                                                  type="text"
                                                  placeholder="Улица, дом, корпус/строение"/>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                        <br/>
                        {additionalInformationScheme.scheme && <JSONForm
                            onChange={e => change("additional_information")({target: {value: e.formData}})}
                            schema={additionalInformationScheme.scheme}
                            uiSchema={additionalInformationScheme.ui}
                            validator={validator}
                            formData={form.additional_information}/>}
                    </Card.Body>
                </Card>

                <Form.Check
                    label="Есть законный представитель"
                    checked={form.is_with_legal_representative}
                    onChange={e => change("is_with_legal_representative")({target: {value: e.target.checked}})}/>
                <br/>
                <h4>Печать</h4>
                <Button size={"lg"} style={{marginRight: 12}}  disabled={busy}  variant="secondary" onClick={e => print("resolution")}>
                    Постановление
                </Button>
                <Button size={"lg"} variant="secondary"  disabled={busy} onClick={e => print("protocol")}>
                    Протокол
                </Button>
                <hr/>
                <br/>

                <div style={{textAlign: "center"}}>
                    <Button size={"lg"} variant="primary" disabled={busy || form.approved_at} onClick={submit}>
                        Подтвердить
                    </Button>
                </div>
                <br/>
            </Form>}
        </Container>
    );
}

export default App;
