import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {v4 as uuidv4, validate as uuidValidate} from 'uuid'
import * as Sentry from "@sentry/react";

const params = new URLSearchParams(window.location.search)
const id = params.get("id")
const revisionId = parseInt(params.get("revision_id"))
const token = params.get("token")

function refresh() {
    window.location.search = "?id=" + uuidv4() + "&revision_id=" + revisionId + "&token=" + token;
}




if (!uuidValidate(id)) {
    refresh()
}

Sentry.init({
    dsn: "https://9721a4b7d925e3a672ddfd1905ca2139@o4506200971608064.ingest.sentry.io/4506200974229504",
    integrations: [new Sentry.BrowserTracing()],

    tracesSampleRate: 1.0
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    id && <App id={id} refresh={refresh} token={token} revisionId={revisionId}/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
